import { defineStore } from "pinia";

export const useOrderStore = defineStore("OrderStore", {
    state: () => {
        return {
            factoryCosts: {
                bmat: 0,
                rmat: 0,
                emat: 0,
                hemat: 0,
            },
            mpfCosts: {
                bmat: 0,
                rmat: 0,
                emat: 0,
                hemat: 0,
            },
            factory: {
                small_arms: [],
                heavy_arms: [],
                heavy_ammunition: [],
                utilities: [],
                medical: [],
                supplies: [],
                uniforms: [],
            },
            mpf: {
                small_arms: [],
                heavy_arms: [],
                heavy_ammunition: [],
                supplies: [],
                uniforms: [],
                vehicles: [],
                shipables: []
            }
        };
    },
    actions: {
        addFactoryOrders() {
            this.factoryCosts.bmat = 0;
            this.factoryCosts.rmat = 0;
            this.factoryCosts.emat = 0;
            this.factoryCosts.hemat = 0;

            for (const category in this.factory) {
                this.factory[category].forEach((item) => {
                    this.factoryCosts.bmat += (item.cost.bmat) ? item.cost.bmat : 0;
                    this.factoryCosts.rmat += (item.cost.rmat) ? item.cost.rmat : 0;
                    this.factoryCosts.emat += (item.cost.emat) ? item.cost.emat : 0;
                    this.factoryCosts.hemat += (item.cost.hemat) ? item.cost.hemat : 0;
                });
            }
        },
        addMpfOrders() {
            let isEmpty = [];

            this.mpfCosts.bmat = 0;
            this.mpfCosts.rmat = 0;
            this.mpfCosts.emat = 0;
            this.mpfCosts.hemat = 0;

            let bmat = 0;
            let rmat = 0;
            let emat = 0;
            let hemat = 0;

            for (const category in this.mpf) {

                bmat = 0;
                rmat = 0;
                emat = 0;
                hemat = 0;
                
                if(!this.mpf[category].length > 0) {
                    isEmpty.push(false);
                } else {
                    isEmpty.push(true);
                }

                if(this.mpf[category][0]) {
                    bmat = (this.mpf[category][0].cost.bmat) ? this.getItemDiscountCosts(this.mpf[category].length, this.mpf[category][0].cost.bmat) : 0;
                    rmat = (this.mpf[category][0].cost.rmat) ? this.getItemDiscountCosts(this.mpf[category].length, this.mpf[category][0].cost.rmat) : 0;
                    emat = (this.mpf[category][0].cost.emat) ? this.getItemDiscountCosts(this.mpf[category].length, this.mpf[category][0].cost.emat) : 0;
                    hemat = (this.mpf[category][0].cost.hemat) ? this.getItemDiscountCosts(this.mpf[category].length, this.mpf[category][0].cost.hemat) : 0;
                }

                if(!isEmpty.includes(true)) {
                    this.mpfCosts.bmat = 0;
                    this.mpfCosts.rmat = 0;
                    this.mpfCosts.emat = 0;
                    this.mpfCosts.hemat = 0;
                }
                
                this.mpfCosts.bmat += bmat;
                this.mpfCosts.rmat += rmat;
                this.mpfCosts.emat += emat;
                this.mpfCosts.hemat += hemat;
                // if(this.mpf[category][0].cost.bmat != undefined) {
                //     console.log(this.mpf[category][0].cost.bmat)
                // }
               
                // totalRmat = (item.cost.rmat) ? this.getItemDiscountCosts(this.mpf[category].length, this.mpf[category][0].cost.rmat) : 0;
                // totalEmat = (item.cost.emat) ? this.getItemDiscountCosts(this.mpf[category].length, this.mpf[category][0].cost.emat) : 0;
                // totalHemat = (item.cost.hemat) ? this.getItemDiscountCosts(this.mpf[category].length, this.mpf[category][0].cost.hemat) : 0;

                // this.mpf[category].forEach((item) => {

                //     console.log('active item')

                //     // totalBmat = (item.cost.bmat) ? this.getItemDiscountCosts(this.mpf[category].length, this.mpf[category][0].cost.bmat) : 0;
                //     // totalRmat = (item.cost.rmat) ? this.getItemDiscountCosts(this.mpf[category].length, this.mpf[category][0].cost.rmat) : 0;
                //     // totalEmat = (item.cost.emat) ? this.getItemDiscountCosts(this.mpf[category].length, this.mpf[category][0].cost.emat) : 0;
                //     // totalHemat = (item.cost.hemat) ? this.getItemDiscountCosts(this.mpf[category].length, this.mpf[category][0].cost.hemat) : 0;
                // });
            }
        },
        getItemDiscountCosts(number_of_crates, resource_base_cost) {
            let resource_total_cost = 0

            for(let i = 1; i <= number_of_crates; i++) {
                if(i > 5) resource_total_cost += Math.floor(resource_base_cost - (resource_base_cost * (5/10)))
                if(i <= 5) resource_total_cost += Math.floor(resource_base_cost - (resource_base_cost * (i/10)))
            }

            return resource_total_cost
        },
        clearAll() {
            for (const category in this.factory) {
                this.factory[category] = [];

                this.factoryCosts.bmat = 0;
                this.factoryCosts.rmat = 0;
                this.factoryCosts.emat = 0;
                this.factoryCosts.hemat = 0;
            }
            for (const category in this.mpf) {
                this.mpf[category] = [];

                this.mpfCosts.bmat = 0;
                this.mpfCosts.rmat = 0;
                this.mpfCosts.emat = 0;
                this.mpfCosts.hemat = 0;
            }
        }
    }
})