<template>
  <div class="tooltip">
    <button :style="{ backgroundImage: 'url(./assets/images/items/' + item.imgName + ')' }" 
            class="tooltip__image" 
            v-on:click="onSubmit(item)"
           
           :disabled="checkItem(item)"
         

    >{{item.itemName}}</button>



    <div class="tooltip-card">
      
        <p class="title">{{item.itemName}}</p>
     
        <div class="tooltip-desc">
            <p v-if="item.itemClass" class="orange">{{item.itemClass}}</p>
            <p>{{item.itemDesc}}</p>
            <p v-if="item.highVelocityBonus" class="orange">{{item.highVelocityBonus}}</p>
            <p v-if="item.ammoUsed" class="gray">{{item.ammoUsed}}</p>
            <p v-if="item.isMountable" class="orange">This can be attached to certain vehicles</p>
            <p v-if="item.damageType" class="angry-orange">{{item.damageType}}</p>
            <p v-if="item.damageDesc" class="orange">{{item.damageDesc}}</p>
            <p v-if="item.vehiclepen" class="orange">{{item.vehiclePen}}</p>
            <p v-if="item.vehiclePenChance" class="orange">{{item.vehiclePenChance}}</p>
            <p class="angry-orange">Produces a crate of {{item.numberProduced}}x {{item.itemName}}(s)</p>
            <p  v-if="item.isTeched" class="blue">Requires Tech</p>
            <div class="resource">
                <div class="resource-container" v-if="item.cost.bmat">
                    <img src="/assets/images/resources/bmat.png" alt="basic materials">
                    <div>{{item.cost.bmat}}</div>
                </div>
                <div class="resource-container" v-if="item.cost.rmat">
                    <img src="/assets/images/resources/rmat.png" alt="refined materials">
                    <div>{{item.cost.rmat}}</div>
                </div>
                <div class="resource-container" v-if="item.cost.hemat">
                    <img src="/assets/images/resources/hemat.png" alt="heavy explosive material">
                    <div>{{item.cost.hemat}}</div>
                </div>
                <div class="resource-container" v-if="item.cost.emat" alt="explosive material">
                    <img src="/assets/images/resources/emat.png">
                    <div>{{item.cost.emat}}</div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { useFactoryStore } from '@/stores/FactoryStore';
import { useOrderStore } from '@/stores/OrderStore';

export default {
    name: 'Item',
    setup() {
        const factoryStore = useFactoryStore();
        const orderStore = useOrderStore();

        const factory  = factoryStore;
        const order = orderStore;
        
        return {
            factory,
            order,
        }
    },
    methods: {
        onSubmit(item) {
            if (this.factory.factorySetting[0] == 'factory') {
                const preparedItem = {
                    itemName: item.itemName,
                    imgName: item.imgName,
                    itemCategory: item.itemCategory,
                    cost: item.cost
                }
                this.order.factory[item.itemCategory].push(preparedItem)
                this.order.addFactoryOrders();
            }

            if (this.factory.factorySetting[0] == 'mpf') {
                const preparedItem = {
                    itemName: item.itemName,
                    imgName: item.imgName,
                    itemCategory: item.itemCategory,
                    cost: item.cost
                }
               this.order.mpf[item.itemCategory].push(preparedItem)
               this.order.addMpfOrders();
            }
        },
        checkItem(item) {
            
            if (this.factory.factorySetting == 'factory') return false;

            for(const category in this.order.mpf) {

                let activeCategory = '';

                for(let i = 0; i < this.order.mpf[category].length; i++) {
                    activeCategory = this.order.mpf[category][i].itemCategory;
                    if(this.order.mpf[category][0].itemName != item.itemName && activeCategory == item.itemCategory) return true
                }
            }
        }
    },
    props: ['item']
}
</script>

<style scoped>
    .tooltip {
        width: 106px;
        height: 106px;
        padding: 3px;

        cursor: pointer;
    }

    button {
        width: 106px;
        height: 106px;

        word-break: break-all;
        box-sizing: border-box;

        display: block;
        padding: 16px;
        cursor: pointer;

        background-color: #4D4D4D;
        border: none;

        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-indent: -9999px;
        font-size: 0px;
    }
    button:disabled {
        background-color: #242424;
        cursor: not-allowed;
    }
    .tooltip__image {
        background-size: 74px;
        background-position: center;
        background-repeat: no-repeat;
    }

    /* handle mouse hover */
    .tooltip:hover>.tooltip-card {
        display: block;
    }
    .tooltip:hover>button:disabled + .tooltip-card {
        display: none;
    }
    .tooltip:hover>button {
        background-color: #737472;
    }
    .tooltip:hover>button:disabled {
        background-color: #242424;
    }
    .tooltip:active>button {
        transition: .1s;
        background-color: #3A3A3A;
    }

    /* handle keyboard focus  */
    .tooltip:focus-within>.tooltip-card {
        display: block;
    }
    
    .tooltip:focus-within>button {
        background-color: #737472;
    }
    
    .tooltip:active>button {
        transition: .1s;
        background-color: #3A3A3A;
    }

    .tooltip-card {
        display: none;
        position: relative;
        width: 666px;
        color: #fff;
        background-color: #000;
        word-break: break-word;
    }

    @media screen and (max-width: 700px) {
        .tooltip {
            height: auto;
        }
    }

    @media screen and (min-width: 700px) {
        /* six across */
       .tooltip:nth-child(6n + 2) .tooltip-card {
            right: 112px;
        }
        .tooltip:nth-child(6n + 3) .tooltip-card {
            right: 224px;
        }
        .tooltip:nth-child(6n + 4) .tooltip-card {
            right: 336px;
        }
        .tooltip:nth-child(6n + 5) .tooltip-card {
            right: 448px;
        }
        .tooltip:nth-child(6n + 6) .tooltip-card {
            right: 560px;
        }
    }

    @media screen and (max-width: 699px) and (min-width: 588px) {
        /* five across */
        .tooltip-card {
            width: 554px;
        }

        .tooltip:nth-child(5n + 2) .tooltip-card {
            right: 112px;
        }
        .tooltip:nth-child(5n + 3) .tooltip-card {
            right: 224px;
        }
        .tooltip:nth-child(5n + 4) .tooltip-card {
            right: 336px;
        }
        .tooltip:nth-child(5n + 5) .tooltip-card {
            right: 448px;
        }
    }
    @media screen and (max-width: 587px) and (min-width: 476px) {
        /* four across */
        .tooltip-card {
            width: 442px;
        }

        .tooltip:nth-child(4n + 2) .tooltip-card {
            right: 112px;
        }
        .tooltip:nth-child(4n + 3) .tooltip-card {
            right: 224px;
        }
        .tooltip:nth-child(4n + 4) .tooltip-card {
            right: 336px;
        }
    }
    @media screen and (max-width: 475px) and (min-width: 364px) {
        /* three across */
        .tooltip-card {
            width: 330px;
        }

        .tooltip:nth-child(3n + 2) .tooltip-card {
            right: 112px;
        }

         .tooltip:nth-child(3n + 3) .tooltip-card {
            right: 224px;
        }
    }
    @media screen and (max-width: 363px) and (min-width: 100px) {
        /* two across */
        .tooltip-card {
            width: 218px;
        }

        .tooltip:nth-child(2n + 2) .tooltip-card {
            right: 112px;
        }
    }

    .resource-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 120px;
        margin-bottom: 15px;
        background-color: #737472;
    }

    .resource {
        display: inline-block;
    }

    .resource-container img {
        width: 30px;
        height: 30px;
        margin: 0px;
        box-sizing: initial;
        background-color: initial;
    }

    .resource-container div {
        padding: 10px;
    }

    .title {
        margin: 0px;
        padding: 5px 5px 5px 10px;
        background-color: #737472;
    }

    .tooltip-desc {
        padding: 0px 10px;
    }

    .orange {
        color: #BA9B6D;
    }

    .gray {
        color: #D4D4D4;
    }

    .angry-orange {
        color: #E77C48;
    }

    .blue {
        color: #235683;
    }

    .active {
        background-color: #E77C48;
    }
</style>